.search_input {
  border-radius: 4px !important;
  border: 1px solid rgba(229, 224, 221, 1) !important;
  height: 48px !important;
  padding: 12px 16px 12px 16px !important;
  width: 100% !important;
  color: black !important;
  background-color: white !important;
}
.custom_input {
  padding: 5px 0 4px 0 !important;
  font-size: 16px !important;
  color: black !important;
  background-color: white !important;
}

.search_input::after,
.search_input::before {
  display: none !important;
}
.custom_input::placeholder {
  color: black !important;
  opacity: 0.6 !important;
}
.input_adornment {
  color: black !important;
  cursor: pointer !important;
  height: 18px !important;
  width: 18px !important;
}
