.icon {
  color: #d0d5dd !important;
}

.breadcrumbs {
  color: #003c42 !important;
}

.breadcrumbs a {
  text-decoration: none !important;
  color: #1f1f1f !important;
  font-size: 14px !important;
}

.breadcrumbs a:hover {
  text-decoration: underline !important;
}

.breadcrumbs p {
  font-family: 'SoehneMedium' !important;
}

.breadcrumbs span {
  color: #1f1f1f !important;
  font-size: 14px !important;
}
