.container {
  margin-top: 25px !important;
  padding: 0 !important;
  /* box-shadow: 0px 1px 3px 0px #00000033 !important; */
  margin-left: 0 !important;
}

.table {
  margin-top: 25px !important;
}

.bgPagination {
  background-color: red;
}
