.custom-input textarea:nth-child(2) {
  display: none !important;
}

/* .custom-input-textarea textarea:last-child() {
  display: none !important;
} */

/* .multiColorSelectInput input:disabled::placeholder {
  color: rgba(0, 0, 0, 0.88);
} */

.multiColorSelectInput input::placeholder {
  color: rgba(0, 0, 0, 0.88);
}

.multiColorSelectInput fieldset {
  /* border: 1px solid rgba(0, 0, 0, 0.2) !important; */
  border: none !important;
  height: 40px;
  margin-top: 10px;
}

.multiColorSelectInput fieldset:hover {
  /* border: 1px solid rgba(0, 0, 0, 0.45) !important; */
  border: none !important;
}

.desktopPicker input:hover {
  background-color: rgba(142, 128, 113, 0.08) !important;
  height: 24px;
  border-radius: 4px;
}

.activity-textarea textarea:hover {
  background-color: rgba(142, 128, 113, 0.08) !important;
}

.hide-on-focus {
  display: none;
}

.small-image {
  cursor: pointer;
}

.small-image:hover {
  border-radius: 50%;
  background-color: rgba(142, 128, 113, 0.08);
}

.customNoDate {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 6px;
  font-size: 14px;
  font-family: 'InterRegular';
  min-width: 150px;
  cursor: pointer;
}

.customNoDate:hover {
  background-color: rgba(142, 128, 113, 0.08) !important;
}
