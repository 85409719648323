.button-contained:hover {
  background-color: rgb(2, 143, 109) !important;
  /* filter: brightness(92%) !important; */
  color: #ffffff !important;
}

.button-contained:focus {
  background-color: rgb(0, 136, 105) !important;
  /* filter: brightness(90%) !important; */
  color: #ffffff !important;
}

.button-contained:active {
  background-color: rgb(0, 130, 100) !important;
  /* filter: brightness(88%) !important; */
  color: #ffffff !important;
}

.button-contained {
  background-color: rgba(0, 155, 119, 1) !important;
}

.button-contained:disabled {
  filter: brightness(88%) !important;
  color: rgba(0, 0, 0, 0.1) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.outlined-activity {
  border: none !important;
}

.button-profile {
  color: rgba(0, 0, 0, 0.7);
}

.button-profile:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.button-profile:disabled {
  color: red !important;
}

/* .button-remove {
  color: rgba(0, 0, 0, 0.7) !important;
} */

.button-remove:hover {
  background-color: white !important;
  color: rgba(204, 0, 48, 1) !important;
  border: 1px solid rgba(204, 0, 48, 1) !important;
  font-family: 'InterSemiBold' !important;
}

.button-outlined:focus {
  /* filter: brightness(90%) !important; */
  color: #009b77 !important;
  background-color: rgba(0, 155, 119, 0.12) !important;
}

.button-outlined:active {
  /* filter: brightness(88%) !important; */
  color: #009b77 !important;
  background-color: rgba(0, 155, 119, 0.16) !important;
}

.button-outlined:disabled {
  /* filter: brightness(88%) !important; */
  color: rgba(0, 0, 0, 0.2) !important;
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.outlined-activity:disabled {
  color: rgba(0, 0, 0, 0.2) !important;
  background-color: white !important;
  border: none !important;
}

.button-outlined:hover {
  color: #009b77 !important;
  background-color: rgba(0, 155, 119, 0.08) !important;
}

.button-text:hover {
  color: #009b77 !important;
  background-color: rgba(0, 155, 119, 0.08) !important;
}

.button-text:focus {
  /* filter: brightness(90%) !important; */
  color: #009b77 !important;
  background-color: rgba(0, 155, 119, 0.12) !important;
}

.button-text:active {
  /* filter: brightness(88%) !important; */
  color: #009b77 !important;
  background-color: rgba(0, 155, 119, 0.16) !important;
}

.button-text:disabled {
  /* filter: brightness(88%) !important; */
  color: rgba(0, 0, 0, 0.2) !important;
  background-color: white !important;
}
