@font-face {
  font-family: 'SoehneRegular';
  src: local('SoehneRegular'),
    url('./assets/fonts/soehne-buch.woff2') format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'SoehneMedium';
  src: local('SoehneMedium'),
    url('./assets/fonts/soehne-halbfett.woff2') format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'SoehneBold';
  src: local('SoehneBold'),
    url('./assets/fonts/soehne-dreiviertelfett.woff2') format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'InterLight';
  src: local('InterLight'),
    url('./assets/fonts/Inter-Light.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'InterSemiBold';
  src: local('InterSemiBold'),
    url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'),
    url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'InterMedium';
  src: local('InterMedium'),
    url('./assets/fonts/Inter-Medium.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'InterBold';
  src: local('InterBold'),
    url('./assets/fonts/Inter-Bold.ttf') format('truetype');
  font-display: block;
}

body {
  margin: 0;
  font-family: 'InterMedium';
  color: #002129;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset {
  border: 1px solid #eeeeee !important;
}

@media (max-width: 1089px) {
  .App {
    display: none !important;
  }

  .screenSize {
    z-index: 1000;
    background: #000;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .screenSize img {
    width: 72px;
    height: 48px;
  }

  .screenSize p,
  .screenSize h4 {
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
  }

  .screenSize p {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .screenSize h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  .gc-section {
    display: none;
  }
}

@media (min-width: 1090px) {
  .screenSize {
    display: none !important;
  }

  .css-nvq9cu-MuiTypography-root {
    font-family: 'InterMedium';
  }

  .placeholderStyleHint::placeholder {
    font-size: 14px;
    color: #b3b3b3;
    font-family: 'SoehneRegular', sans-serif;
  }

  .react-input-emoji--container {
    margin: 0 !important;
    padding: 12px 16px !important;
    gap: 10px !important;
    width: 394px !important;
    height: 56px !important;
    background: #f9f9f9 !important;
    border: 1px solid #eeeeee !important;
    border-radius: 4px !important;
    margin-top: 8px !important;
  }

  .react-input-emoji--wrapper {
    height: 100% !important;
  }

  .react-input-emoji--input {
    padding: 5px 0 !important;
    height: 90% !important;
  }

  .react-input-emoji--placeholder {
    left: 0 !important;
  }
}

.toast-container {
  height: auto;
  padding: 4px 40px 4px 12px !important;
  border-radius: 8px !important;
  gap: 8px !important;
  font-family: 'InterRegular' !important;
  color: rgba(255, 255, 255, 1) !important;
  color: 'black' !important;
  font-size: 14px !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2) !important;
  width: 330px !important;
}

.custom-close-icon-dark {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
}

.custom-close-icon-light {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
}

#form-file-upload {
  border-radius: 4px;
  display: flex;
  position: relative;
  gap: 8px;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
