.header {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  margin-top: 0 !important;
}

.tabs {
  display: flex !important;
  align-items: center !important;
  gap: 16px !important;
  padding-top: 0 !important;
}

.tab,
.selected_tab {
  font-size: 20px !important;
  line-height: 32px !important;
  color: #4c4c4c !important;
  cursor: pointer !important;
  text-decoration: none !important;
}

.selected_tab {
  font-family: 'InterBold' !important;
  color: #00818c !important;
}

.tab_divider {
  background-color: #c7c7c7 !important;
}

.header_btns {
  display: flex !important;
  align-items: center !important;
  gap: 24px !important;
  flex-wrap: wrap;
  padding-top: 0 !important;
}

.sort_btn {
  background-color: white !important;
  padding: 0px 16px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 16px !important;
  cursor: pointer !important;
  color: #4c4c4c !important;
  height: 40px !important;
}

.sort_btn span {
  color: rgba(31, 31, 31, 1) !important;
  display: flex !important;
  justify-content: center !important;
  gap: 10px !important;
  line-height: 26px;
}

.span_inactive {
  border: 1px solid #eeeeee !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
}

.span_active {
  border: 1px solid #00818c !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
}

.sort_btn svg {
  font-size: 25px !important;
  color: #00818c !important;
}

.menu {
  border-radius: 8px !important;
  width: 210px !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  margin-left: 120px !important;
}

.label {
  font-family: 'InterBold' !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: rgba(31, 31, 31, 1) !important;
  font-weight: 700 !important;
}

.track {
  font-family: 'InterBold' !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.menu_item,
.selected_menu_item {
  background: white !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-items: flex-start !important;
  align-items: flex-start !important;

  margin-bottom: 5px !important;
  width: 100% !important;
  padding: 8px 12px !important;
  cursor: pointer !important;
}

.menu_item_name {
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1f1f1f !important;
  width: 100%;
  cursor: pointer !important;
}

.menu_item_email {
  font-size: 12px !important;
  line-height: 16px !important;
  color: #7a8081 !important;
}

.menu_item_name_selected {
  color: #00818c !important;
}

.menu_item:hover,
.selected_menu_item:hover {
  background: #edf6f7 !important;
  color: #00818c !important;
}

.selected_menu_item {
  color: #00818c !important;
  background: #edf6f7 !important;
}

.selected_menu_item svg,
.menu_item svg {
  font-size: 14px !important;
  color: #00818c !important;
  background: #edf6f7 !important;
}

.menu_item_name,
.menu_item_email {
  font-family: 'InterRegular' !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.add_btn {
  font-size: 14px !important;
  line-height: 20px !important;
  gap: 13px !important;
  border-radius: 24px !important;
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
}

.add_btn svg {
  font-size: 18px !important;
}

.dayPicker > div > div > fieldset {
  border-color: green;
  background-color: 'red';
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
