.main {
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.heading {
  font-size: 32px !important;
  font-family: "SoehneBold" !important;
  margin-bottom: 36px !important;
  margin-top: 20px;
}

.signup_btn {
  padding: 10px 24px !important;
  margin-top: 15px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "SoehneMedium" !important;
}

.login {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

.login_btn {
  color: black !important;
}

.signup_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  margin-top: 10%;
}
