.main {
  background-position: right !important;
  background-repeat: no-repeat !important;
}

.container {
  min-height: 100vh;
}

.button_container {
  padding-top: 20px;
  margin-bottom: 40px;
}

.main_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .main {
    background-size: contain !important;
  }
}
@media (max-width: 900px) {
  .main {
    background-image: none !important;
  }
}
